import {mercuryAppUrl, mercuryUrl} from '~/data/mercury'

export const mercuryBlogUrl = `${mercuryUrl}/blog`

const authRoutes = {
  signup: `${mercuryAppUrl}/signup`,
  signupBBorPB: `${mercuryAppUrl}/signup/business-or-personal`,
  login: `${mercuryAppUrl}/login`,
  autofillRedirect: `${mercuryAppUrl}/redirect/autofill`,
}

export const externalLinks = {
  auth: authRoutes,
  contactUs: `${mercuryAppUrl}/contact-us`,
  mobileApps: {
    iOS: 'https://itunes.apple.com/us/app/mercury/id1491984028',
    android: 'https://play.google.com/store/apps/dev?id=7011991507842528981',
  },
  demo: 'https://demo.mercury.com/dashboard',
  engBlog: {
    datePicker:
      'https://medium.com/mercury-bank/a-magic-date-input-using-parser-combinators-in-typescript-3c779741bf4c',
  },
  helpCenter: {
    // use canonical link for help center homepage
    // https://mercurytechnologies.slack.com/archives/C03B6J79TFB/p1729795037140019
    index:
      'https://support.mercury.com/hc/en-us/sections/28736349558420-Getting-Started',
  },
  logoDownloads: {
    mercuryBrandKitZip:
      'https://cdn.mercury.com/fem/logo-downloads/mercury-brand-kit.zip',
  },
  blog: {
    financialHealth: `${mercuryBlogUrl}/bank-financial-health`,
    fundraising: `${mercuryBlogUrl}/category/raise`,
    futureOfRetailInvesting: `'${mercuryBlogUrl}/podcasts/future-of-fintech/retail-investing'`,
    index: mercuryBlogUrl,
    internationalWires: `${mercuryBlogUrl}/international-wires`,
    sweepNetwork: `${mercuryBlogUrl}/understanding-bank-sweep-network`,
    partnerBanks: `${mercuryBlogUrl}/inside-mercury/how-mercury-works-with-partner-banks`,
    raise: `${mercuryBlogUrl}/inside-mercury/mercury-raise`,
    safesByMercury: `${mercuryBlogUrl}/inside-mercury/safes-by-mercury`,
    ventureDebt101: `${mercuryBlogUrl}/venture-debt-101`,
  },
  choice: {
    // NB: some of these agreement URLs also need to be updated in mercury-web and MWB, if you update them
    legal: {
      depositAgreement:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Choice+Commercial+Deposit+Account+Agreement+-+2024.03.07.pdf',
      debitCardAgreement:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Choice+-+Mercury+Commercial+Debit+Card+Agreement+-+10_22_2024.pdf',
      savingsAccountAgreement:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Choice+-+Commercial+Savings+Account+Agreement+-+Update+3.7.24+.pdf',
      sweepDisclosure:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Choice+-+Mercury+Deposit+Program+Disclosure+Statement+-+05_01_24.pdf',
      sweepBankList:
        'https://www.reichandtang.com/files/ddm/bank-list/pbl-rtncu.pdf',
    },
  },
  choicePersonalBanking: {
    legal: {
      // NB: some of these agreement URLs also need to be updated in mercury-web and MWB, if you update them
      depositAgreement:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Choice+Consumer+Deposit+Account+Agreement+-+2025.02.06.pdf',
      debitCardAgreement:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Choice+Consumer+Debit+Card+Agreement+-+2025.02.06.pdf',
      savingsAccountAgreement:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Choice+Consumer+Savings+Account+Agreement+-+2025.02.11.pdf',
    },
  },
  column: {
    legal: {
      depositAndSavingsAgreement:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Column+N.A.+Commercial+Deposit+Account+%26+Savings+Account+Agreement+1-29-25.pdf',
      sweepDisclosure:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Column+Sweep+Program+Agreement+-+Mercury.pdf',
      debitCardAgreement:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Mercury+Commercial+Debit+Card+Agreement+11.01.24.pdf',
    },
  },
  events: 'https://events.mercury.com',
  evolve: {
    legal: {
      documents:
        'https://www.getevolved.com/openbanking/fdic-insured-sweep-program/fdic-program-bank-list/',
      // NB: some of these agreement URLs also need to be updated in mercury-web and MWB, if you update them
      privacy: 'https://www.getevolved.com/privacy-policy/',
      // Deprecated, leaving for reference
      // sweepDisclosure:
      // 'https://co-mercury-prod.s3.amazonaws.com/legal/Evolve+-+Deposit+Program+Custodial+Agreement+-+09_09_22.pdf',
      depositAgreement:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Evolve+-+Mercury+Commercial+Deposit+Account+Agreement+-+Update+3.7.24.pdf',
      debitCardAgreement:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Evolve+-+Mercury+Commercial+Debit+Card+Agreement+-+10_22_2024.pdf',
      savingsAccountAgreement:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Evolve+-+Mercury+Commercial+Savings+Account+Agreement+-+Update+3.7.24.pdf',
      sweepDisclosure:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Evolve+-+Deposit+Program+Custodial+Agreement+-+12_21_22.pdf',
      sweepBankList: 'https://www.getevolved.com/mercury-sweep/',
    },
  },
  bbva: {
    legal: {
      terms:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Mercury+BBVA+Account+Terms+%26+Conditions.pdf',
      depositAgreement:
        'https://co-mercury-prod.s3.amazonaws.com/legal/Mercury+BBVA+Non-Consumer+Deposit+Account+Agreement.pdf',
      esign:
        'https://www.bbvacompass.com/disclosure/online-banking/electronic-communications-agreement.html?download=true',
      privacy: 'https://www.bbvausa.com/policy/privacy-policy.html',
    },
  },
  meridian: {
    index: 'https://meridian.mercury.com',
    benHorowitz: 'https://meridian.mercury.com/ben-horowitz',
  },
  marketing: {
    billPay: 'https://mercury.com/blog/inside-mercury/october-2022-product-updates',
    fastCompany10:
      'https://www.fastcompany.com/90847467/most-innovative-companies-finance-2023',
    forbes50: 'https://www.forbes.com/lists/fintech50/?sh=7051fe6d18d3',
    situationWithSVB: 'https://mercury.com/blog/svb-info',
    securityAtMercury:
      'https://mercury.com/blog/inside-mercury/multi-factor-authentication',
    seriesTeaEpOne: 'https://www.youtube.com/watch?v=OZkdgJdZn4M',
    sweep: 'https://mercury.com/blog/understanding-bank-sweep-network',
  },
  social: {
    twitter: 'https://twitter.com/mercury',
    angel: 'https://angel.co/mercury',
    linkedin: 'https://www.linkedin.com/company/mercuryhq',
    youtube: 'https://www.youtube.com/c/mercuryfi',
  },
  api: {
    docs: 'https://docs.mercury.com/reference',
  },
  treasury: {
    feeBrochure:
      'https://files.adviserinfo.sec.gov/IAPD/Content/Common/crd_iapd_Brochure.aspx?BRCHR_VRSN_ID=885473',
    fitchRatings:
      'https://www.fitchratings.com/entity/morgan-stanley-institutional-fund-trust-ultra-short-income-portfolio-96280173',
    mulsx:
      'https://www.morganstanley.com/im/en-us/individual-investor/product-and-performance/mutual-funds/taxable-fixed-income/ultra-short-income-portfolio.html',
    vusxx:
      'https://investor.vanguard.com/investment-products/mutual-funds/profile/vusxx',
  },
  raise: {
    expertSessions: 'https://sessions.mercury.com',
    founderCommunity: {
      slackGroup: 'https://founder-community.mercury.com/',
    },
    investorConnect: {
      investorPortal: 'https://investor-portal.mercury.com/',
      pitchForm: 'https://founder-pitch.mercury.com',
    },
  },
  spheres: {
    tickets: 'https://mercuryspheres.splashthat.com/',
  },
} as const

// Paths in this object correlate to https://github.com/MercuryTechnologies/mercury-web/blob/master/src/routing/sitemap.ts -
// adding these as paths rather than full urls/ links in the object above allows us to utilize formatting
// utils such as `mercuryAppUrlWithPath`
export const mercuryAppPaths = {
  accounts: {
    index: '/accounts',
    credit: '/accounts/credit',
  },
  addFunds: {
    wireDetails: '/add-funds/wire',
    check: {
      guidelines: '/add-funds/check/guidelines',
    },
  },
  allPaymentCards: {
    activate: '/activate', // shortcut from marketing materials to activate a physical payment card
  },
  capital: {
    index: '/capital',
  },
  documents: {
    index: '/documents',
    statements: '/documents/statements',
  },
  perkRedirect: '/perks/redirect',
  sendMoney: {
    payment: {
      start: '/send-money/payment',
    },
  },
  settings: {
    team: {
      index: '/settings/team',
    },
    vault: {
      index: '/settings/vault',
    },
  },
  upnRedirectOrDashboard: '/redirect',
  ventureDebt: {
    eligibility: '/venture-debt/eligibility',
  },
}

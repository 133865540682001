'use client'

import classnames from 'classnames'
import _ from 'lodash'
import {usePathname} from 'next/navigation'
import {Fragment, useEffect, useState, type JSX} from 'react'
import {mercuryAppUrl} from '~/data/mercury'
import {HgThemeSelector} from '~/design-system/hg/components'
import {THEME_SWITCHER_ID} from '~/design-system/hg/components/HgTheme/HgThemeSwitcher'
import {type Theme} from '~/design-system/hg/tokens/colors'
import {cn} from '~/design-system/utils'
import MWDSButtonLink from '~/mercuryWebCompat/design-system/MWDSButtonLink'
import MWDSLink from '~/mercuryWebCompat/design-system/MWDSLink'
import {externalLinks, mercuryAppPaths} from '~/routing/external-links'
import {internalLinks} from '~/routing/internal-links'
import {hasLoggedInCookie} from '~/utils/hasLoggedInCookie'
import {LogoAndBrand} from '../Typography'
import styles from './styles.module.css'

export const NAVBAR_ID = 'mercury-navbar'

type HeaderLink = {
  label: string
  key: string
  linkType: 'internal' | 'externalNewTab' | 'externalSameTab'
  mobileRank: number | false // lowest numbers will be higher in the mobile menu, false will be hidden
  badge?: JSX.Element
}

export type HeaderLinkWithURL = HeaderLink & {
  url: string
  linkType: 'internal' | 'externalNewTab' | 'externalSameTab'
  subLinks?: never
}

const NEW_PRODUCT_BANNER_ENABLED = false

const renderNewProductBanner = () => {
  return (
    <div className={cn(styles.newProductBanner, '!bg-surface-elevated text-center')}>
      <div className={styles.bannerContent}>
        {/* Example badge left in the code */}
        {/* <DSBadge
          label="New Product"
          badgeType="pearl"
          size="micro-demi"
          // always give the badge a darkened 'hover' state
          hovered
          className={styles.newProductBadge}
        /> */}
        <span>
          You can now use Mercury to run your{' '}
          <MWDSLink underline href={internalLinks.financialWorkflows}>
            financial workflows.
          </MWDSLink>
        </span>
      </div>
    </div>
  )
}

export type HeaderProps = {
  color?:
    | 'white'
    | 'grey'
    | 'offWhite'
    | 'femGrey'
    | 'femMarcel'
    | 'femMarcel100'
    | 'femMarcel200'
    | 'darkGrey'
    | 'newFEM' // defaults to white
  ctaText?: string
  hideNewProductBanner?: boolean // we want to show this on most fem pages
}

type CTAProps = {
  ctaText?: string
  isLoggedIn: boolean
  color?: string
  className?: string
}

const CTAButtons = ({
  ctaText = 'Open Account',
  isLoggedIn,
  color,
  className,
}: CTAProps) => {
  const pathname = usePathname()
  const pathForOpenAccount =
    pathname === '/personal-banking'
      ? externalLinks.auth.signupBBorPB
      : externalLinks.auth.signup

  return (
    <div className={className}>
      <div className={styles.ctaButtonsDesktop}>
        {isLoggedIn ? (
          <MWDSButtonLink
            className={styles.dashboardCTA}
            variant="tertiary"
            size="little"
            color={color === 'newFEM' ? 'newFEM' : undefined}
            href={`${mercuryAppUrl}${mercuryAppPaths.upnRedirectOrDashboard}`}
            analyticsEvent={{
              type: 'nav-link',
              location: 'nav',
              text: 'Dashboard',
              linkedPath: `${mercuryAppUrl}${mercuryAppPaths.upnRedirectOrDashboard}`,
            }}
          >
            Dashboard
          </MWDSButtonLink>
        ) : (
          <>
            <MWDSButtonLink
              className={styles.loginCTA}
              variant="tertiary"
              size="little"
              href={externalLinks.auth.login}
              color={color === 'newFEM' ? 'newFEM' : undefined}
              analyticsEvent={{
                type: 'nav-link',
                location: 'nav',
                text: 'Log In',
                linkedPath: externalLinks.auth.login,
              }}
            >
              Log In
            </MWDSButtonLink>
            <MWDSButtonLink
              className={styles.primaryCTA}
              variant="primary"
              size="little"
              href={pathForOpenAccount}
              color={color === 'newFEM' ? 'newFEM' : undefined}
              analyticsEvent={{
                type: 'signup',
                location: 'nav',
                text: ctaText,
                linkedPath: pathForOpenAccount,
              }}
            >
              {ctaText}
            </MWDSButtonLink>
          </>
        )}
      </div>
      <div className={styles.ctaButtonsMobile}>
        {isLoggedIn ? (
          <MWDSButtonLink
            className={styles.dashboardCTA}
            variant="secondary"
            size="big"
            color={color === 'newFEM' ? 'newFEM' : undefined}
            href={`${mercuryAppUrl}${mercuryAppPaths.upnRedirectOrDashboard}`}
            analyticsEvent={{
              type: 'nav-link',
              location: 'nav',
              text: 'Dashboard',
              linkedPath: `${mercuryAppUrl}${mercuryAppPaths.upnRedirectOrDashboard}`,
            }}
          >
            Dashboard
          </MWDSButtonLink>
        ) : (
          <>
            <MWDSButtonLink
              variant="secondary"
              size="big"
              href={externalLinks.auth.login}
              color={color === 'newFEM' ? 'newFEM' : undefined}
              analyticsEvent={{
                type: 'nav-link',
                location: 'nav',
                text: 'Log In',
                linkedPath: externalLinks.auth.login,
              }}
            >
              Log In
            </MWDSButtonLink>
            <MWDSButtonLink
              variant="primary"
              size="big"
              href={pathForOpenAccount}
              color={color === 'newFEM' ? 'newFEM' : undefined}
              analyticsEvent={{
                type: 'signup',
                location: 'nav',
                text: ctaText,
                linkedPath: externalLinks.auth.signup,
              }}
            >
              {ctaText}
            </MWDSButtonLink>
          </>
        )}
      </div>
    </div>
  )
}

export default function Header(props: HeaderProps) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [currentThemeOverride, setCurrentThemeOverride] = useState<
    Theme | undefined
  >('neutral')
  const [hasScrolled, setHasScrolled] = useState(false)
  const pathname = usePathname()
  const isHomepage = pathname === '/'

  const showNewProductBanner =
    !props.hideNewProductBanner && NEW_PRODUCT_BANNER_ENABLED && isHomepage

  useEffect(() => {
    const getThemeFromUnderNavbar = _.throttle(
      () => {
        // Check if the navbar has scrolled
        const navbar = document.getElementById(NAVBAR_ID)
        if (navbar) {
          const rect = navbar.getBoundingClientRect()
          const hasScrolled = window.scrollY > rect.top + rect.height
          setHasScrolled(hasScrolled)
        }

        // Check if the item under the navbar is a non-global theme
        const sections = document.querySelectorAll(`[data-id=${THEME_SWITCHER_ID}]`)
        const sectionIsInViewport = Array.from(sections).filter(
          div =>
            div.getBoundingClientRect().top < window.innerHeight &&
            div.getBoundingClientRect().bottom > 32 // 32 is approximately half of the navbar
        )

        const topMostItem = sectionIsInViewport[0]
        if (!topMostItem) return

        const isAppliedGlobally =
          topMostItem.getAttribute('data-apply-globally') !== 'false'
        if (isAppliedGlobally) {
          setCurrentThemeOverride(undefined)
        } else {
          const theme = topMostItem.getAttribute('data-theme') as Theme
          setCurrentThemeOverride(theme)
        }
      },
      17, // approximately 60fps
      {leading: true}
    )

    getThemeFromUnderNavbar()
    window.addEventListener('scroll', getThemeFromUnderNavbar)
    return () => {
      window.removeEventListener('scroll', getThemeFromUnderNavbar)
    }
  }, [currentThemeOverride])

  useEffect(() => {
    setIsLoggedIn(hasLoggedInCookie())
  }, [])

  const renderHeader = () => {
    const isNewFEM = props.color === 'newFEM'
    const FragmentIfNewFEM = isNewFEM ? Fragment : 'div'
    return (
      <HgThemeSelector theme={currentThemeOverride}>
        <div
          className={classnames(
            styles.frame,
            styles[props.color ?? 'white'],
            hasScrolled && styles.hasScrolled,
            showNewProductBanner && styles.withNewProductBanner
          )}
        >
          <div className={styles.blur} />
          {showNewProductBanner && renderNewProductBanner()}
          <div className={classnames(styles.wrapper)} id={NAVBAR_ID}>
            {/* NOTE: this component works differently than in mercury-web. Passing `specific-referrer` means that we show the referrer information IF it exists */}
            {props.color === 'newFEM' ? (
              <div className="flex-1">
                <div className="w-fit">
                  <LogoAndBrand
                    options={{
                      clickable: 'home',
                      kind: 'inherit',
                    }}
                  />
                </div>
              </div>
            ) : (
              <LogoAndBrand
                options={{
                  kind: 'inherit',
                  clickable: 'home',
                }}
              />
            )}

            <FragmentIfNewFEM
              {...(isNewFEM
                ? {}
                : {
                    className: classnames(styles.desktopNavBar),
                  })}
            >
              <CTAButtons
                className={
                  isNewFEM
                    ? classnames(styles.desktopNavBar, 'justify-end lg:flex-1')
                    : undefined
                }
                {...props}
                isLoggedIn={isLoggedIn}
              />
            </FragmentIfNewFEM>
          </div>
        </div>
      </HgThemeSelector>
    )
  }

  if (props.color === 'newFEM') {
    return renderHeader()
  }

  return (
    <>
      <div
        className={classnames(
          styles.coloredBackground,
          styles[props.color ?? 'white'],
          showNewProductBanner && styles.withNewProductBanner
        )}
      />
      {renderHeader()}
    </>
  )
}
